var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    attrs: {
      "data-aos": "fade"
    }
  }, [_c('v-row', _vm._l(_vm.galleries, function (gallery, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('dialog-secondary', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-hover', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (_ref2) {
                var hover = _ref2.hover;
                return [_c('v-card', _vm._g(_vm._b({
                  attrs: {
                    "flat": "",
                    "tile": ""
                  }
                }, 'v-card', attrs, false), on), [_c('v-img', {
                  attrs: {
                    "aspect-ratio": 1 / 1,
                    "src": gallery.image
                  },
                  scopedSlots: _vm._u([{
                    key: "placeholder",
                    fn: function () {
                      return [_c('no-image')];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('v-expand-transition', [hover ? _c('div', {
                  staticClass: "v-card--reveal d-none d-lg-block transition-fast-in-fast-out"
                }, [_c('div', {
                  staticClass: "v-card--reveal-inner"
                }, [_c('tit-primary', {
                  attrs: {
                    "titSize": "tit--xs"
                  }
                }, [_vm._v(_vm._s(gallery.subject))]), _c('btn-arrow-secondary', {
                  staticClass: "v-size--xx-small",
                  attrs: {
                    "direction": "right",
                    "color": "white"
                  }
                })], 1)]) : _vm._e()]), _c('div', {
                  staticClass: "v-card--reveal d-lg-none"
                }, [_c('div', {
                  staticClass: "v-card--reveal-inner"
                }, [_c('tit-primary', {
                  attrs: {
                    "titSize": "tit--xxs"
                  }
                }, [_vm._v(_vm._s(gallery.subject))])], 1)])], 1)], 1)];
              }
            }], null, true)
          })];
        }
      }], null, true)
    }, [_c('v-img', {
      attrs: {
        "contain": "",
        "max-width": "100%",
        "max-height": "calc(100vh - 56px - 24px)",
        "src": gallery.image
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('no-image')];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "5"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }