<template>
    <div v-if="notification">
        <v-divider class="grey darken4" style="border-width: 2px 0 0" />
        <div class="pa-12px px-md-20px py-md-16px">
            <tit-primary>{{ notification.subject }}</tit-primary>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px">
            <v-row>
                <v-col cols="auto">
                    <u-txt-items txtItem titCols="auto" txtCols="" titWidth="" titClass="txt--lg" txtClass="txt--lg">
                        <template #tit>작성일</template>
                        <template #txt>{{ notification.createdAt.toDate() }}</template>
                    </u-txt-items>
                </v-col>
                <v-col cols="auto">
                    <u-txt-items txtItem titCols="auto" txtCols="" titWidth="" titClass="txt--lg" txtClass="txt--lg">
                        <template #tit>조회수</template>
                        <template #txt>{{ notification.viewCount }}</template>
                    </u-txt-items>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="notification.upload" cols="auto">
                    <u-txt-items txtItem titCols="auto" txtCols="" titWidth="" titClass="txt--lg" txtClass="txt--lg">
                        <template #tit>첨부파일</template>
                        <template #txt>
                            <v-row v-for="(file,index) in notification.upload" :key="index" class="row--xxs">
                                <v-col cols="12">
                                    <a class="d-inline-flex align-center" @click="download(file, file.name.split('/').pop())">
                                        <txt-primary class="txt--lg">{{ file.name.split("/").pop() }}</txt-primary>
                                        <i class="icon icon-download ml-8px" />
                                    </a>
                                </v-col>
                            </v-row>
                        </template>
                    </u-txt-items>
                </v-col>
            </v-row>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px min-h-200px">
            <div class="overflow-x-auto">
                <div class="grey--text font-size-16">
                    <div v-html="notification.content.replace(/\n/g, '<br>')" />
                </div>
            </div>
        </div>
        <v-divider class="border-light" />

        <div class="btn-wrap btn-wrap--sm">
            <v-row align="center" justify="end">
                <v-col cols="auto">
                    <btn-secondary2 @click="$router.go(-1)">리스트</btn-secondary2>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    components: {
        TitPrimary,
        TxtPrimary,
        BtnSecondary2,

        UTxtItems,
    },
    data() {
        return {
            notification: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        download,
        async init() {
            try {
                var { notification } = await api.v1.center.notifications.get({ _id: this.$route.params._notification });
                if(notification.upload){ notification.upload = await Promise.all(notification.upload.map(async(file)=> await api.getResource(file))) }

                this.notification = notification;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
