<template>
    <div v-if="education">
        <v-divider class="grey darken4" style="border-width: 2px 0 0" />
        <div class="pa-12px px-md-20px py-md-16px">
            <tit-primary>{{ education.title }}</tit-primary>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px">
            <u-txt-items>
                <v-col cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>접수기간</template>
                        <template #txt>{{ `${$dayjs(education.enrollmentEndDate).format("YYYY.MM.DD HH:mm")} 까지` }}</template>
                    </u-txt-items>

                    <u-txt-items txtItem size="lg">
                        <template #tit>교육기간</template>
                        <template #txt>{{ `${$dayjs(education.educationStartDate).format("YYYY. MM. DD.")} ~ ${$dayjs(education.educationEndDate).format("YYYY. MM. DD.")}` }}</template>
                    </u-txt-items>

                    <u-txt-items txtItem size="lg">
                        <template #tit>모집인원</template>
                        <template #txt>{{ education.capacity }}</template>
                    </u-txt-items>

                    <u-txt-items v-if="education.upload?.length > 0"  txtItem titCols="auto" txtCols="" titWidth="" titClass="txt--lg" txtClass="txt--lg">
                        <template #tit>첨부파일</template>
                        <template #txt>
                            <v-row v-for="(file,index) in education.upload" :key="index" class="row--xxs">
                                <v-col cols="12">
                                    <a class="d-inline-flex align-center" @click="download(file, file.name.split('/').pop())">
                                        <txt-primary class="txt--lg">{{ file.name.split("/").pop() }}</txt-primary>
                                        <i class="icon icon-download ml-8px" />
                                    </a>
                                </v-col>
                            </v-row>
                        </template>
                    </u-txt-items>
                </v-col>
            </u-txt-items>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px min-h-200px">
            <div class="overflow-x-auto">
                <div class="grey--text font-size-16">
                    <div v-html="education.content" />
                </div>
            </div>
        </div>
        <v-divider class="border-light" />

        <div class="btn-wrap btn-wrap--sm">
            <v-row align="center" justify="end">
                <v-col cols="auto">
                    <btn-secondary2 @click="$router.go(-1)">리스트</btn-secondary2>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    components: {
        TitPrimary,
        TxtPrimary,
        BtnSecondary2,

        UTxtItems,
    },
    data() {
        return {
            _education: this.$route.params._education,
            education: {},
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        download,
        async init(){
            await this.search();
        },
        async search(){
            try{
                let { education } = await api.v1.education.get({id: this.$route.params._education});
                if(education.upload?.length > 0) education.upload = await Promise.all(education.upload.map(async(file)=> await api.getResource(file)))
                
                this.education = education;
            } catch(error){
                console.error(error)
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
