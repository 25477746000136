var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tabs-wrap tabs-wrap--lg"
  }, [_c('tabs-primary', [_c('tab-primary', {
    attrs: {
      "to": "/facility-space/YONGSIN"
    }
  }, [_vm._v("용신동")]), _c('tab-primary', {
    attrs: {
      "to": "/facility-space/DAPSIMNI"
    }
  }, [_vm._v("답십리")])], 1)], 1), _c('u-img-txt-items', {
    attrs: {
      "data-aos": "fade",
      "items": _vm.spaces,
      "to": "/facility-space"
    }
  }), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "5"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }