<template>
    <div>
        <!-- <board-filter data-aos="fade"
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        /> -->

        <div v-if="code === DDM_WORK_TYPES.STUDIO.value" class="tabs-wrap tabs-wrap--lg">
            <tabs-primary>
                <tab-primary :to="`YONGSIN?code=${code}`">용신동</tab-primary>
                <tab-primary :to="`DAPSIMNI?code=${code}`">답십리</tab-primary>
            </tabs-primary>
        </div>

        <u-img-txt-items data-aos="fade" :items="equipments"/>

        <div class="pagination-wrap"><v-pagination v-model="page" :length="pageCount" total-visible="5" @input="search(true)"/></div>
    </div>
</template>

<script>
import api from "@/api";
import { DDM_CENTER_TYPES, DDM_WORK_TYPES } from "@/assets/variables";

import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";

import BoardFilter from "@/components/client/board/board-filter.vue";
import UImgTxtItems from "@/components/publish/styles/items/u-img-txt-items.vue";

export default {
    components: {
        TabsPrimary,
        TabPrimary,
        PaginationPrimary,

        BoardFilter,
        UImgTxtItems,
    },
    data() {
        return {
            DDM_WORK_TYPES,
            limit: 9,
            equipments: [],
            summary: {
                totalCount: 0
            },
            page: 1,
            pageCount: 0,
            code: this.$route.query.code || null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.$route.query, page: this.page },
                });
            }
            var { summary, equipments } = await api.v1.equipment.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: {
                    center: this.center,
                    work: this.code
                }
            });

            this.equipments = equipments;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit) || 1;
        },
    },
    computed:{
        center(){
            if(Object.values(DDM_CENTER_TYPES).map(({ value }) => value).includes(this.$route.path.split("/").pop())){
                return this.$route.path.split("/").pop();
            }

            return null;
        },
    },
    watch: {
        center(){
            this.page = 1;
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped></style>
