var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.equipment ? _c('div', [_c('v-divider', {
    staticClass: "grey darken4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-16px"
  }, [_c('tit-primary', [_vm._v(_vm._s(_vm.equipment.title))])], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-24px"
  }, [_c('u-txt-items', [_vm.equipment.center ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("센터")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.equipment.center === _vm.DDM_CENTER_TYPES.YONGSIN.value ? _vm.DDM_CENTER_TYPES.YONGSIN.text : _vm.DDM_CENTER_TYPES.DAPSIMNI.text))];
      },
      proxy: true
    }], null, false, 1685898925)
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("이용시간")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.equipment.time))];
      },
      proxy: true
    }], null, false, 1726760129)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("수량")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.equipment.quantity))];
      },
      proxy: true
    }], null, false, 3278854523)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("위치안내")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.equipment.location))];
      },
      proxy: true
    }], null, false, 3571587212)
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-24px min-h-200px"
  }, [_c('div', {
    staticClass: "overflow-x-auto"
  }, [_c('div', {
    staticClass: "grey--text font-size-16"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.equipment.content)
    }
  })])])]), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-secondary2', {
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }