<template>
    <v-dialog v-model="shows" v-bind="$attrs" class="v-dialog--img">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <div class="v-dialog__inner">
            <v-toolbar color="transparent">
                <v-spacer></v-spacer>
                <v-btn icon tile color="white" class="v-size--xx-large" @click.stop="close"><u-icon color="white--text">close</u-icon></v-btn>
            </v-toolbar>
            <v-card flat tile color="transparent" width="100%">
                <slot />
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {
        title: { type: String, default: "" },
    },
    components: {
        UIcon,
        Tit,
    },
    data() {
        return {
            shows: false,
        };
    },
    mounted() {},
    methods: {
        close() {
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-dialog--img::v-deep {
    > .v-overlay__content {
        border-radius: 0;
        margin: 0;
        padding: var(--container-gutter);
        max-width: 100%;
        max-height: 100vh;
        width: auto;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        > .v-dialog__inner {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
@media (min-width: 1464px) {
    .v-dialog--img::v-deep {
        > .v-overlay__content {
            max-width: calc(var(--container) + (var(--container-gutter) * 2));
        }
    }
}
</style>
