<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="센터소식" tabActive="사업공고" bg="/images/sub/sub-visual6.jpg">
                <template #tit>센터소식</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>사업공고</template>

            <board-list v-if="!_business" />
            <board-view v-else/>
            <!-- <router-view name="board" code="" skin="board-default" hide-write-button v-bind="{ category }" /> -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import BoardList from "@/components/client/board/skin/board-default/list.vue";
import BoardView from "@/components/client/board/skin/board-default/view.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        BoardList,
        BoardView,
    },
    computed: {
        category() {
            return this.$route.query.category || undefined;
        },
        _business(){
            return this.$route.params._business;
        }
    },
};
</script>
