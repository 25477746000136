<template>
    <v-tabs v-bind="{ ...attrs_tabs_common, ...$attrs }" :grow="false" class="v-tabs--density-compact">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tabs>
    <!-- <u-tabs-slider v-model="TabsPrimaryValue" v-bind="$attrs" @change="TabsPrimaryValueChange">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-tabs-slider> -->
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";
import UTabsSlider from "@/components/publish/styles/tabs/u-tabs-slider.vue";

export default {
    props: {
        value: { type: [Number, String] },
    },
    components: {
        UTabsSlider,
    },
    data() {
        return {
            attrs_tabs_common,
        };
    },
    computed: {
        TabsPrimaryValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {
        TabsPrimaryValueChange(newValue) {
            this.TabsPrimaryValue = newValue;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .v-tabs--density-compact {
        ::v-deep {
            .v-tab {
                min-width: 200px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
