<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="센터소식" tabActive="공지사항" bg="/images/sub/sub-visual6.jpg">
                <template #tit>센터소식</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>공지사항</template>

            <!-- 1. 게시판 리스트: 베이직 스킨 -->
            <notification-list v-if="!$route.params._notification"></notification-list>
            <notification-view v-else></notification-view>
            <!-- 2. 게시판 작성: 베이직 스킨 -->
            <!-- <board-basic-input></board-basic-input> -->
            <!-- 3. 게시판 뷰: 베이직 스킨 -->
            <!-- <board-basic-view></board-basic-view> -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        NotificationList,
        NotificationView,
    },
};
</script>
