<template>
    <u-dialog-img v-model="shows" v-bind="$attrs">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-dialog-img>
</template>

<script>
import UDialogImg from "@/components/publish/styles/dialogs/u-dialog-img.vue";

export default {
    props: {},
    components: {
        UDialogImg,
    },
    data() {
        return {
            shows: false,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-dialog {
        width: auto;
        &__inner {
            width: auto;
        }
    }
}
</style>
