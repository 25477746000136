var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.space ? _c('div', [_c('v-divider', {
    staticClass: "grey darken4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-16px"
  }, [_c('tit-primary', [_vm._v(_vm._s(_vm.space.title))])], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-24px"
  }, [_c('u-txt-items', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("이용시간")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.space.time))];
      },
      proxy: true
    }], null, false, 3567872207)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("면적")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.space.capacity))];
      },
      proxy: true
    }], null, false, 1875722270)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("위치안내")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.space.location))];
      },
      proxy: true
    }], null, false, 1877843458)
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-24px min-h-200px"
  }, [_c('div', {
    staticClass: "overflow-x-auto"
  }, [_c('div', {
    staticClass: "grey--text font-size-16"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.space.content)
    }
  })])])]), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-secondary2', {
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }