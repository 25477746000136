var render = function render(){
  var _vm$education$upload;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.education ? _c('div', [_c('v-divider', {
    staticClass: "grey darken4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-16px"
  }, [_c('tit-primary', [_vm._v(_vm._s(_vm.education.title))])], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-24px"
  }, [_c('u-txt-items', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("접수기간")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(`${_vm.$dayjs(_vm.education.enrollmentEndDate).format("YYYY.MM.DD HH:mm")} 까지`))];
      },
      proxy: true
    }], null, false, 1505963371)
  }), _c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("교육기간")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(`${_vm.$dayjs(_vm.education.educationStartDate).format("YYYY. MM. DD.")} ~ ${_vm.$dayjs(_vm.education.educationEndDate).format("YYYY. MM. DD.")}`))];
      },
      proxy: true
    }], null, false, 3000335585)
  }), _c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("모집인원")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(_vm._s(_vm.education.capacity))];
      },
      proxy: true
    }], null, false, 1717873620)
  }), ((_vm$education$upload = _vm.education.upload) === null || _vm$education$upload === void 0 ? void 0 : _vm$education$upload.length) > 0 ? _c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "",
      "titClass": "txt--lg",
      "txtClass": "txt--lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("첨부파일")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return _vm._l(_vm.education.upload, function (file, index) {
          return _c('v-row', {
            key: index,
            staticClass: "row--xxs"
          }, [_c('v-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('a', {
            staticClass: "d-inline-flex align-center",
            on: {
              "click": function ($event) {
                _vm.download(file, file.name.split('/').pop());
              }
            }
          }, [_c('txt-primary', {
            staticClass: "txt--lg"
          }, [_vm._v(_vm._s(file.name.split("/").pop()))]), _c('i', {
            staticClass: "icon icon-download ml-8px"
          })], 1)])], 1);
        });
      },
      proxy: true
    }], null, false, 4213419368)
  }) : _vm._e()], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "pa-12px px-md-20px py-md-24px min-h-200px"
  }, [_c('div', {
    staticClass: "overflow-x-auto"
  }, [_c('div', {
    staticClass: "grey--text font-size-16"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.education.content)
    }
  })])])]), _c('v-divider', {
    staticClass: "border-light"
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--sm"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-secondary2', {
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }