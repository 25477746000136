<template>
    <div>
        <!-- <board-filter data-aos="fade"
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        /> -->

        <div data-aos="fade">
            <v-row>
                <v-col v-for="(gallery, index) in galleries" :key="index" cols="6" md="4">
                    <dialog-secondary>
                        <template #activator="{ attrs, on }">
                            <v-hover v-slot="{ hover }">
                                <v-card flat tile v-bind="attrs" v-on="on">
                                    <v-img :aspect-ratio="1 / 1" :src="gallery.image">
                                        <template v-slot:placeholder>
                                            <no-image />
                                        </template>
                                        <v-expand-transition>
                                            <div v-if="hover" class="v-card--reveal d-none d-lg-block transition-fast-in-fast-out">
                                                <div class="v-card--reveal-inner">
                                                    <tit-primary titSize="tit--xs">{{ gallery.subject }}</tit-primary>
                                                    <btn-arrow-secondary direction="right" color="white" class="v-size--xx-small" />
                                                </div>
                                            </div>
                                        </v-expand-transition>
                                        <div class="v-card--reveal d-lg-none">
                                            <div class="v-card--reveal-inner">
                                                <tit-primary titSize="tit--xxs">{{ gallery.subject }}</tit-primary>
                                            </div>
                                        </div>
                                    </v-img>
                                </v-card>
                            </v-hover>
                        </template>

                        <v-img contain max-width="100%" max-height="calc(100vh - 56px - 24px)" :src="gallery.image">
                            <template v-slot:placeholder>
                                <no-image />
                            </template>
                        </v-img>
                    </dialog-secondary>
                </v-col>
            </v-row>
        </div>

        <div class="pagination-wrap"><v-pagination v-model="page" :length="pageCount" total-visible="5" @input="search(true)" /></div>
    </div>
</template>

<script>
import api from "@/api";

import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";
import ChipPrimary from "@/components/publish/parents/chips/chip-primary.vue";
import ChipPrimary2 from "@/components/publish/parents/chips/chip-primary2.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";
import DialogSecondary from "@/components/publish/parents/dialogs/dialog-secondary.vue";

import BoardFilter from "@/components/client/board/board-filter.vue";
import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";
import UCardFlatTile from "@/components/publish/styles/cards/u-card-flat-tile.vue";
import NoImage from "@/components/publish/styles/cards/no-image.vue";

export default {
    components: {
        TabsPrimary,
        TabPrimary,
        CardSecondary,
        PaginationPrimary,
        ChipPrimary,
        ChipPrimary2,
        TitPrimary,
        BtnArrowSecondary,
        DialogSecondary,

        BoardFilter,
        UTxtItems,
        UCardFlatTile,
        NoImage,
    },
    data() {
        return {
            limit: 9,
            page: 1,
            pageCount: 0,
            galleries: [],
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            var { summary, galleries } = await api.v1.gallery.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
            });
            this.galleries = galleries;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    },
};
</script>

<style lang="scss" scoped>
.v-card {
    &--reveal,
    &--reveal-inner {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    &--reveal {
        height: 100%;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
    }
    &--reveal-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .v-card {
        &--reveal-inner {
            padding: 24px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
