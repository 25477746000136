<template>
    <div>
        <!-- <board-filter data-aos="fade"
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        /> -->

        <div class="tabs-wrap tabs-wrap--lg">
            <tabs-primary>
                <tab-primary to="/facility-space/YONGSIN">용신동</tab-primary>
                <tab-primary to="/facility-space/DAPSIMNI">답십리</tab-primary>
            </tabs-primary>
        </div>

        <u-img-txt-items data-aos="fade" :items="spaces" to="/facility-space"/>

        <!-- <div class="pagination-wrap"><pagination-primary v-model="page" :length="pageCount" total-visible="5" @input="search(true)"/></div> -->
        <div class="pagination-wrap"><v-pagination v-model="page" :length="pageCount" total-visible="5" @input="search(true)"/></div>
    </div>
</template>

<script>
import api from "@/api";

import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";

import BoardFilter from "@/components/client/board/board-filter.vue";
import UImgTxtItems from "@/components/publish/styles/items/u-img-txt-items.vue";

export default {
    components: {
        TabsPrimary,
        TabPrimary,
        PaginationPrimary,

        BoardFilter,
        UImgTxtItems,
    },
    data() {
        return {
            limit: 9,
            spaces: [],
            summary: {
                totalCount: 0
            },
            page: 1,
            pageCount: 0,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            var { summary, spaces } = await api.v1.space.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: { center: this.center }
            });

            this.spaces = spaces;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit) || 1;
        },
    },
    computed:{
        center(){
            return this.$route.path.split("/").pop();
        }
    },
    watch: {
        center(){
            this.page = 1;
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped></style>
