<template>
    <div class="ml-auto mb-20px" :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 500px' : ''">
        <v-row class="row--xxs">
            <v-col cols="12" md="4">
                <select-primary :items="searchKeys" class="v-input--density-compact" @input="searchKey" />
            </v-col>
            <v-col cols="12" md="">
                <text-field-primary placeholder="검색어를 입력하세요." class="v-input--density-compact" @input="searchValue"> </text-field-primary>
            </v-col>
            <v-col cols="12" md="auto">
                <btn-primary block color="grey lighten1" class="v-btn--input-compact" @click="$emit('search')">검색</btn-primary>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TextFieldPrimary from "@/components/publish/parents/forms/text-field-primary.vue";
import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";
import IconSearch from "@/components/publish/styles/icons/icon-search.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    components: {
        TextFieldPrimary,
        SelectPrimary,
        IconSearch,
        BtnPrimary,
    },

    data() {
        return {
            searchKeys: [
                { text: "전체", value: null },
                { text: "제목", value: "subject" },
                { text: "제목 + 내용", value: "subjectcontent" },
            ],
        };
    },
    methods: {
        searchKey(searchKey) {
            this.$emit("searchKey", searchKey);
        },
        searchValue(searchValue) {
            this.$emit("searchValue", searchValue);
        },
    },
};
</script>
