<template>
    <div v-if="space">
        <v-divider class="grey darken4" style="border-width: 2px 0 0" />
        <div class="pa-12px px-md-20px py-md-16px">
            <tit-primary>{{ space.title }}</tit-primary>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px">
            <u-txt-items>
                <!-- <v-col v-for="(item, index) in testItems" :key="index" cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>{{ item.title }}</template>
                        <template #txt>{{ item.text }}</template>
                    </u-txt-items>
                </v-col> -->
                <v-col cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>이용시간</template>
                        <template #txt>{{ space.time }}</template>
                    </u-txt-items>
                </v-col>
                <v-col cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>면적</template>
                        <template #txt>{{ space.capacity }}</template>
                    </u-txt-items>
                </v-col>
                <v-col cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>위치안내</template>
                        <template #txt>{{ space.location }}</template>
                    </u-txt-items>
                </v-col>
            </u-txt-items>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px min-h-200px">
            <div class="overflow-x-auto">
                <div class="grey--text font-size-16">
                    <div v-html="space.content" />
                </div>
            </div>
        </div>
        <v-divider class="border-light" />

        <div class="btn-wrap btn-wrap--sm">
            <v-row align="center" justify="end">
                <v-col cols="auto">
                    <btn-secondary2 @click="$router.go(-1)">리스트</btn-secondary2>
                </v-col>
            </v-row>
        </div>

        <!-- <div>작성일 : {{ $dayjs(board.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
        <div>조회수 : {{ board.viewCount.format() }}</div> -->
    </div>
</template>

<script>
import api from "@/api";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    props: {
        board: { type: Object, default: () => {} },
    },
    components: {
        TitPrimary,
        BtnSecondary2,

        UTxtItems,
    },
    data() {
        return {
            testItems: [
                { title: "이용시간", text: "평일 09:00 ~ 18:00" },
                { title: "수용인원", text: "21명" },
                { title: "위치안내", text: "3층 공용재단실" },
            ],
            space: {}
        };
    },
    mounted(){
        this.init();
    },
    methods:{
        async init(){
            this.search();
        },
        async search(){
            try{
                const { space } = await api.v1.space.get({ id: this.$route.params._space });

                this.space = space;
            } catch(error){
                console.error(error);
            }

        }
    }
};
</script>

<style lang="scss" scoped></style>
