<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공용재단실" tabActive="보유장비" bg="/images/sub/sub-visual4.jpg">
                <template #tit>공용재단실</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <board-list v-if="!_equipment" />
            <board-view v-else/>
            <!-- <router-view name="board" code="" skin="img-txt" hide-write-button v-bind="{ category }" /> -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import BoardList from "@/components/client/board/skin/img-txt/equipment/list.vue";
import BoardView from "@/components/client/board/skin/img-txt/equipment/view.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        BoardList,
        BoardView,
    },
    computed: {
        _equipment() {
                return this.$route.params._equipment || undefined;
        },
    },
};
</script>
