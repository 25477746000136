<template>
    <div v-if="equipment">
        <v-divider class="grey darken4" style="border-width: 2px 0 0" />
        <div class="pa-12px px-md-20px py-md-16px">
            <tit-primary>{{ equipment.title }}</tit-primary>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px">
            <u-txt-items>
                <v-col cols="12" v-if="equipment.center">
                    <u-txt-items txtItem size="lg">
                        <template #tit>센터</template>
                        <template #txt>{{ equipment.center === DDM_CENTER_TYPES.YONGSIN.value ? DDM_CENTER_TYPES.YONGSIN.text : DDM_CENTER_TYPES.DAPSIMNI.text }}</template>
                    </u-txt-items>
                </v-col>
                <v-col cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>이용시간</template>
                        <template #txt>{{ equipment.time }}</template>
                    </u-txt-items>
                </v-col>
                <v-col cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>수량</template>
                        <template #txt>{{ equipment.quantity }}</template>
                    </u-txt-items>
                </v-col>
                <v-col cols="12">
                    <u-txt-items txtItem size="lg">
                        <template #tit>위치안내</template>
                        <template #txt>{{ equipment.location }}</template>
                    </u-txt-items>
                </v-col>
            </u-txt-items>
        </div>
        <v-divider class="border-light" />

        <div class="pa-12px px-md-20px py-md-24px min-h-200px">
            <div class="overflow-x-auto">
                <div class="grey--text font-size-16">
                    <div v-html="equipment.content" />
                </div>
            </div>
        </div>
        <v-divider class="border-light" />

        <div class="btn-wrap btn-wrap--sm">
            <v-row align="center" justify="end">
                <v-col cols="auto">
                    <btn-secondary2 @click="$router.go(-1)">리스트</btn-secondary2>
                </v-col>
            </v-row>
        </div>

        <!-- <div>작성일 : {{ $dayjs(board.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
        <div>조회수 : {{ board.viewCount.format() }}</div> -->
    </div>
</template>

<script>
import api from "@/api";
import { DDM_CENTER_TYPES } from "@/assets/variables";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    props: {
        board: { type: Object, default: () => {} },
    },
    components: {
        TitPrimary,
        BtnSecondary2,

        UTxtItems,
    },
    data() {
        return {
            DDM_CENTER_TYPES,
            equipment: {},
        };
    },
    mounted(){
        this.init();
    },
    methods:{
        async init(){
            this.search();
        },
        async search(){
            try{
                const { equipment } = await api.v1.equipment.get({ id: this.$route.params._equipment });
                
                this.equipment = equipment;
            } catch(error){
                console.error(error);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
