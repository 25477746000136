<template>
    <div>
        <!-- <board-filter data-aos="fade"
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        /> -->

        <div data-aos="fade">
            <v-row>
                <v-col v-for="(education, index) in educations" :key="index" cols="12">
                    <card-secondary :to="`./edu-education/${education._id}`" innerClass="w-100 px-12px py-16px px-md-16px pa-lg-32px" class="d-flex flex-row">
                        <template #innerHead>
                            <v-divider vertical class="primary align-self-stretch" style="border-width: 0 0 0 2px" />
                        </template>

                        <v-row align="center" justify="space-between" class="row--xxs flex-nowrap" :style="$vuetify.breakpoint.mdAndUp ? 'max-width:calc(100% - 74px)' : 'max-width:calc(100% - 60px)'">
                            <v-col>
                                <div>
                                    <tit-primary titSize="tit--sm" class="text-truncate">{{ education.title }}</tit-primary>
                                </div>
                            </v-col>
                            <v-col cols="auto" class="text-right" :style="$vuetify.breakpoint.mdAndUp ? 'min-width:74px' : 'min-width:60px'">
                                <div v-if="!education.isClosed">
                                    <div v-if="setStatus(education.enrollmentStartDate, education.enrollmentEndDate) === ENROLLMENT_STATUS.PLANNED.text">
                                        <chip-primary3 small>{{ ENROLLMENT_STATUS.PLANNED.text }}</chip-primary3>
                                    </div>
                                    <div v-if="setStatus(education.enrollmentStartDate, education.enrollmentEndDate) === ENROLLMENT_STATUS.IN_PROGRESS.text">
                                        <chip-primary2 small>{{ ENROLLMENT_STATUS.IN_PROGRESS.text }}</chip-primary2>
                                    </div>
                                    <div v-if="setStatus(education.enrollmentStartDate, education.enrollmentEndDate) === ENROLLMENT_STATUS.CLOSED.text">
                                        <chip-primary small>{{ ENROLLMENT_STATUS.CLOSED.text }}</chip-primary>
                                    </div>
                                </div>
                                <div v-else>
                                    <chip-primary small>{{ ENROLLMENT_STATUS.CLOSED.text }}</chip-primary>
                                </div>
                            </v-col>
                        </v-row>

                        <v-divider class="my-12px my-md-16px my-md-24px" />
                        <u-txt-items>
                            <v-col cols="12">
                                <u-txt-items txtItem titCols="auto" txtCols="" size="lg">
                                    <template #tit>접수기간</template>
                                    <template #txt>{{ $dayjs(education.enrollmentStartDate).format("YYYY. MM. DD") }} ~ {{ $dayjs(education.enrollmentEndDate).format("YYYY. MM. DD") }}</template>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items txtItem titCols="auto" txtCols="" size="lg">
                                    <template #tit>교육기간</template>
                                    <template #txt>{{ $dayjs(education.educationStartDate).format("YYYY. MM. DD") }} ~ {{ $dayjs(education.educationEndDate).format("YYYY. MM. DD") }}</template>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items txtItem titCols="auto" txtCols="" size="lg">
                                    <template #tit>모집인원</template>
                                    <template #txt>{{ education.capacity }}</template>
                                </u-txt-items>
                            </v-col>
                        </u-txt-items>
                    </card-secondary>
                </v-col>
            </v-row>
        </div>

        <div class="pagination-wrap"><v-pagination v-model="page" :length="pageCount" total-visible="5" @input="search(true)"/></div>
    </div>
</template>

<script>
import api from "@/api";

import { ENROLLMENT_STATUS } from "@/assets/variables"
import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";
import ChipPrimary from "@/components/publish/parents/chips/chip-primary.vue";              // 접수마감
import ChipPrimary2 from "@/components/publish/parents/chips/chip-primary2.vue";            // 모집중
import ChipPrimary3 from "@/components/publish/parents/chips/chip-primary3.vue";            // 접수예정
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";

import BoardFilter from "@/components/client/board/board-filter.vue";
import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    components: {
        TabsPrimary,
        TabPrimary,
        CardSecondary,
        PaginationPrimary,
        ChipPrimary,
        ChipPrimary2,
        ChipPrimary3,
        TitPrimary,

        BoardFilter,
        UTxtItems,
    },
    data() {
        return {
            limit: 10,
            educations: [],
            filter: {
                searchKey: null,
                searchValue: null,
            },
            page: 1,
            pageCount: 0,

            ENROLLMENT_STATUS
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                console.log(routable)
                this.$router.push({
                    query: { page: this.page },
                });
            }

            var { summary, educations } = await api.v1.education.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.educations = educations;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        setStatus(start, end){
            const now = this.$dayjs().valueOf();
            start = this.$dayjs(start).valueOf();
            end = this.$dayjs(end).valueOf();
            
            if(now < start){
              return ENROLLMENT_STATUS.PLANNED.text;
            };

            if(start <= now && now <= end){              
              return ENROLLMENT_STATUS.IN_PROGRESS.text;
            };
            
            if(end < now){              
              return ENROLLMENT_STATUS.CLOSED.text;
            };

            return null;
        }, 
    },
};
</script>

<style lang="scss" scoped>
.v-card.v-card--flat {
    .v-divider.primary {
        -webkit-transition: all ease-out 0.5s;
        -ms-transition: all ease-out 0.5s;
        transition: all ease-out 0.5s;
    }
    &:hover {
        .v-divider.primary {
            border-color: var(--v-grey-darken4) !important;
        }
    }
}
</style>
