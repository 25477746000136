<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공간안내" tabActive="공간안내" bg="/images/sub/sub-visual2.jpg" class="sub-head--none-tabs">
                <template #tit>공간안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <board-list v-if="!_space"/>
            <board-view v-else/>
            <!-- <router-view name="board" code="" skin="img-txt" hide-write-button v-bind="{ category }" /> -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import BoardList from "@/components/client/board/skin/img-txt/space/list.vue";
import BoardView from "@/components/client/board/skin/img-txt/space/view.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        BoardList,
        BoardView,
    },
    computed: {
        _space() {
            return this.$route.params._space || undefined;
        },
    },
};
</script>
s
