<template>
    <div>
        <board-filter data-aos="fade"
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="()=> {page=1; search()}"
        />

        <table-secondary data-aos="fade" class="v-table--board v-table--mobile table-fixed">
            <template v-slot:header>
                <thead>
                    <tr>
                        <th-primary style="width: 8%">번호</th-primary>
                        <th-primary style="width: 74%">제목</th-primary>
                        <th-primary style="width: 14%">작성일</th-primary>
                        <th-primary style="width: 12%">조회</th-primary>
                    </tr>
                </thead>
            </template>
            <template v-slot:body>
                <tbody>
                    <tr v-for="(business, index) in fixed" :key="`${index}-fixed`" class="cursor-pointer" @click="$router.push(`${$route.path}/${business._id}`)">
                        <!-- <td class="text-center d-none d-md-table-cell"><v-icon>mdi-alert-circle-outline</v-icon></td> -->
                        <td class="text-center d-none d-md-table-cell"><b class="red--text">공지</b></td>
                        <td class="v-table--board__tit">
                            <div class="text-truncate"><v-icon class="d-md-none mr-6px">mdi-alert-circle-outline</v-icon>{{ business.subject }}</div>
                        </td>
                        <td class="text-center">{{ business.createdAt.toDate() }}</td>
                        <td class="text-center">{{ business.viewCount }}</td>
                    </tr>

                    <tr v-for="(business, index) in businesses" :key="`${index}-business`" class="cursor-pointer"  @click="$router.push(`${$route.path}/${business._id}`)">
                        <td class="text-center d-none d-md-table-cell">{{ (page - 1) * limit + index + 1 }}</td>
                        <td class="v-table--board__tit">
                            <div class="text-truncate">{{ business.subject }}</div>
                        </td>
                        <td class="text-center">{{ business.createdAt.toDate() }}</td>
                        <td class="text-center">{{ business.viewCount }}</td>
                    </tr>
                </tbody>
            </template>
        </table-secondary>

        <div class="pagination-wrap"><v-pagination v-model="page" :length="pageCount" total-visible="5" @input="search(true)" /></div>
    </div>
</template>

<script>
import api from "@/api";

import TabsPrimary from "@/components/publish/parents/tabs/tabs-primary.vue";
import TabPrimary from "@/components/publish/parents/tabs/tab-primary.vue";
import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import TableSecondary from "@/components/publish/parents/tables/table-secondary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";

import BoardFilter from "@/components/client/board/board-filter.vue";

export default {
    components: {
        TabsPrimary,
        TabPrimary,
        TablePrimary,
        TableSecondary,
        ThPrimary,
        TdPrimary,
        PaginationPrimary,

        BoardFilter,
    },
    data() {
        return {
            filter: {
                isNotice: false,
                searchKey: null,
                searchValue: null,
            },
            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            fixed: [],
            businesses: [],

        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            var { businesses: fixed } = await api.v1.center.businesses.gets({
                headers: {
                    limit: 0,
                },
                params: { isNotice: true },
            });
            this.fixed = fixed;

            var { summary, businesses } = await api.v1.center.businesses.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.businesses = businesses;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    },
};
</script>

<style lang="scss" scoped></style>
