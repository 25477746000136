var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "교육안내",
            "tabActive": "교육안내",
            "bg": "/images/sub/sub-visual3.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("교육안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("교육안내")];
      },
      proxy: true
    }])
  }, [!_vm._education ? _c('board-list') : _c('board-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }